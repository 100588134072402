/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}


.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
}

/********************/
/*       SHOP       */
/********************/

/* Banner-All */
.shop {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-shop.webp');
	background-position: center;
	background-size: cover;
}

.container-shop-title {
	padding: 3rem;
	display: flex;
	background-color: #ddd;
}

.container-shop {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
	gap: .5rem;
	padding: 2rem 20rem;
}

.container-categories-shop {
	display: flex;
	gap: 3.5rem;
	justify-content: center;
	align-items: center;
	padding: 3rem 0;
}

.container-category-shop {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	border: none;
	outline: none;
	cursor: pointer;
	background-color: transparent;
}

.container-category-shop img {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
}

.container-category-shop p {
	font-weight: 600;
}

.container-filter-search {
	padding: 1rem 25rem;
	display: flex;
	justify-content: space-between;
}

.container-filter-products {
	display: flex;
	gap: 3rem;
	position: relative;
}

.input-search-products {
	position: relative;
}

.container-search-input {
	position: absolute;
	left: 48%;
	top: 45%;
	padding: .5rem;
	transform: translate(-50%, -50%);
}

.input-search {
	outline: none;
	box-sizing: border-box;
	height: 2.5rem;
	width: 0;
	padding: 0 1rem;
	color: var(--footer-color);
	border-radius: 2.8rem;
	font-size: 1rem;
	border: 1px solid var(--footer-color);
	transition: all .7s ease;
}

::placeholder {
	color: #777;
}

.search-button {
	position: absolute;
	right: .3rem;
	top: .5rem;
	width: 2.5rem;
	height: 2.5rem;
	background: var(--footer-color);
	line-height: 2.9rem;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	transition: .5s;
}

.search-button:hover {
	background-color: var(--primary-color);
}

.search-button button {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

.icon-search-glass {
	font-size: 1.2rem;
	color: var(--white-color);
	line-height: 5rem;
	transition: all -7s ease;
}

.container-search-input:hover .input-search {
	width: 12rem;
}

.input-search:focus,
.input-search:active {
	width: 12rem;
}

.disclosure-filter {
	display: flex;
	justify-content: space-between;
	padding: .5rem;
	align-items: center;
	background-color: var(--background-light);
	border: none;
	cursor: pointer;
	font-weight: 500;
	font-size: .9rem;
	gap: 1rem;
	transition: all .5s ease;
}

.disclosure-filter:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

.contain-disclosure {
	position: relative;
}

.disclosure-panel {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	padding: 1rem;
	font-size: .9rem;
	font-weight: 500;
	position: absolute;
	z-index: 201;
	top: 2.15rem;
	background-color: var(--white-color);
}

.select-filter-wrapper {
	display: flex;
	justify-content: space-between;
}

.submit-button-filter {
	display: flex;
	padding: .5rem 1rem;
	border: none;
	justify-content: center;
	background-color: var(--footer-color);
	cursor: pointer;
	color: var(--white-color);
	transition: all .5s ease;
}

.submit-button-filter:hover {
	background-color: var(--primary-color);
}

.pagination-controls {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	padding-bottom: 3rem;
}

.pagination-button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	cursor: pointer;
	background-color: var(--background-light);
	padding: .5rem 1rem;
	font-weight: 500;
	transition: all .5s ease;
}

.pagination-button:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

.pagination-button-icon {
	font-size: 1.1rem;
}

/********************/
/*  DETAIL-PRODUCT  */
/********************/

.container-search {
	min-height: 25.3vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

/********************/
/*  DETAIL-PRODUCT  */
/********************/
/* Banner-All */
.detail-product {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-shop.webp');
	background-position: center;
	background-size: cover;
	min-height: 30vh !important;
}

.container-detail-products {
	display: flex;
	flex-direction: column;
	padding: 3rem 15rem;
	background-color: #F0F0F0;
	padding-top: 0;
}

.contain-product {
	display: grid;
	grid-template-columns: 5fr 4fr;
	grid-gap: 2rem;
	padding: 4rem 2rem;
}

.container-image-product {
	width: 40rem;
	height: 40rem;
}

.container-image-product img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.container-product-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-right: 8rem;
}

.detail-title {
	font-size: 2.2rem;
	font-weight: 300;
}

.detail-price {
	font-size: 1.6rem;
	font-weight: 500;
}

.container-minus-plus {
	display: flex;
	gap: .3rem;
	align-items: center;
	padding: .5rem;
}

.minusplus-icon {
	font-size: 1.4rem;
	padding: .2rem;
	border: none;
	background-color: var(--light-text);
	cursor: pointer;
}

.config-icon {
	display: flex;
	padding: .1rem;
}

.contain-quantity {
	display: flex;
	justify-content: center;
	font-size: 1.3rem;
	width: 3rem;
	padding: .1rem;
	background-color: var(--light-text);
}

.total-price-detail {
	padding: 1rem 0;
	font-size: 1.2rem;
}

.container-handle-product {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.add-cart-button {
	display: flex;
	padding: .5rem 1.5rem;
	font-size: 1rem;
	border: none;
	background-color: var(--footer-color);
	color: var(--light-text);
	transition: all .5s ease;
	cursor: pointer;
}

.add-cart-button:hover {
	background-color: var(--primary-color);
}

.favorite-icon {
	color: #FB3640;
	font-size: 2rem;
}

.container-detail-description {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: .7rem 0;
	border-bottom: 1px solid #e4e4e4;
}

.toggle-description {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
}

.detail-description {
	padding-top: 1.5rem;
}

.detail-details {
	padding-top: 1.5rem;
	font-weight: 600;
}

.detail-details span {
	font-weight: 400;
}

.container-share-with {
	display: flex;
	justify-content: space-between;
	padding: .7rem 0;
	align-items: center;
	border-bottom: 1px solid #e4e4e4;
}

.social-share-with {
	display: flex;
	gap: .5rem;
	
}

.icons-share {
	color: var(--background-dark);
	cursor: pointer;
	transition: all .3s ease;
}

.icons-share:hover {
	color: var(--primary-color);
}

.container-related-products {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 3rem;
	gap: 4rem;
}

.card-related {
	overflow: scroll;
	display: flex;
	gap: 2rem;
	justify-content: center;
}

.container-form-reviews {
	display: flex;
	flex-direction: column;
	gap: .8rem;
	padding: 2rem 1rem 0;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.contain-msg-review {
	display: flex;
	flex-direction: column;
	gap: .8rem;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.review-msg-input {
	width: 20rem;
	outline: none;
	border: 2px solid #AAA;
	background-color: transparent;
	transition: all .5s ease;
	padding: .5rem;

}

.review-msg-input:focus {
	background-color: var(--white-color);
	border: 2px solid var(--primary-color);
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
	}

	/* SHOP */
	.container-shop {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 2rem .5rem;
	}

	.container-filter-search {
		flex-direction: column;
		gap: 3rem;
		padding: 3rem 2rem;
		justify-content: center;
		align-items: center;
	}

	.container-filter-products {
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;
	}

	.container-categories-shop {
		overflow: scroll;
		justify-content: flex-start;
		padding: 3rem 2rem;
	}

	.input-search {
		width: 12rem;
	}

	/* PRODUCT-DETAIL */
	.container-detail-products {
		padding: 3rem 2rem;
	}

	.contain-product {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 0 3rem;
	}

	.container-image-product {
		width: 20rem;
		height: 20rem;
	}

	.container-product-info {
		padding: 0;
	}

	.container-related-products {
		justify-content: unset;
		align-items: unset;
	}

	.container-related-products h3 {
		text-align: center;
	}

	.related-products-title {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.card-related {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
}